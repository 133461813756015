import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/imgs/academy_logo.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Divider, IconButton, InputAdornment, Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import { setTokens } from "../../store/Slices/authSlice";
import { AuthService } from "../../services/dataService";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { setUserRole } from "../../store/Slices/authoriseSlice";
import { Helmet } from "react-helmet-async";
// import Assessments from "../Assement/Assessments";
// import Dashboard from "../Dashboard";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  maxWidth: "1600px",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" align="center">
      {"Copyright © "}
      <Link
        color="inherit"
        sx={{ textDecoration: "none" }}
        href="https://eduskillsfoundation.org/"
      >
        EduSkills Foundation
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Login() {
  const [email, setEmail] = useState("");
  // const [otpEmail, setOtpEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  // const [otpEmailError, setOtpEmailError] = useState("");
  // const [otpError, setOtpError] = useState("");
  // const [countdown, setCountdown] = useState(30);
  // const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  // const [otp, setOtp] = useState(""); // Add missing state variable
  const [loading, setLoading] = useState(false);
  // const [showOTP, setShowOTP] = useState(true);
  // const [showOTPEmail, setShowOTPEmail] = useState(true);
  // const [showInputOtp, setShowInputOtp] = useState(false);
  // const [clickCount, setClickCount] = useState(0);
  // const [showEmailPassword, setShowEmailPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = createTheme();

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  // useEffect(() => {
  //   console.log("ok-ok");
  //   let countdownInterval;

  //   if (isCountdownActive && countdown > 0) {
  //     countdownInterval = setInterval(() => {
  //       setCountdown((prevCountdown) => prevCountdown - 1);
  //     }, 1000);
  //   }

  //   return () => {
  //     clearInterval(countdownInterval);
  //   };
  // }, [isCountdownActive, countdown]);

  function handleSuccessMessage(message) {
    toast.success(message, {
      autoClose: 2000,
      position: "top-center",
    });
  }

  function handleErrorMessage(message) {
    toast.error(message, {
      autoClose: 2000,
      position: "top-center",
    });
  }

  //..........handleLogoClick for login using email and password
  // const handleLogoClick = (event) => {
  //   setClickCount((prevCount) => prevCount + 1);
  //   if (clickCount + 1 >= 3 && event.key === "k") {
  //     console.log("login using email and password");
  //     setShowEmailPassword(true);
  //   }
  // };
  // document.addEventListener("keydown", handleLogoClick);

  // Add handleSubmit function..................................................

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);

    try {
      const response = await AuthService.login(email, password);
      // if (email === "demo@eduskills.com" && password === "123456") {
      if (response && response.status === 200) {
        const { access_token, refresh_token } = response.data;
        // console.log(access_token, refresh_token);
        // const access_token = "sgashahshq7232382382838283";
        // const refresh_token = "sgashahshq72323823828382833723737";
        dispatch(
          setTokens({ accessToken: access_token, refreshToken: refresh_token })
        );

        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("refreshToken", refresh_token);
        localStorage.setItem("userName", email);
        //   // Fetch user roles after successful login
        // const rolesResponse = await AuthService.roles();
        // const fetchedRoles = rolesResponse.data.roles;
        // const activeRole = fetchedRoles.find((role) => role.status === true);

        //   if (activeRole) {
        //     const activeRoleNameModified = activeRole.role_name
        //       .toLowerCase() // Convert to lowercase
        //       .replace(/ /g, "_"); // Replace spaces with underscores
        // localStorage.setItem("Authorise", "development");
        // dispatch(setUserRole("development"));
        localStorage.setItem("Authorise", "student");
        dispatch(setUserRole("student"));

        const authorise = localStorage.getItem("Authorise");
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");
        if (authorise && accessToken && refreshToken) {
          navigate("/assessment");
          handleSuccessMessage("Login successful");
          // navigate("/dashboard");
        }
        //   } else {
        //     // Handle the case when no active role is found
        //     handleErrorMessage("No active role found");
        //   }
      } else {
        handleErrorMessage("Login failed");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 422) {
          handleErrorMessage("Incorrect email or password. Please try again.");
        } else if (error.response.status === 404) {
          handleErrorMessage("Login failed. Please try again later.");
          console.error("URL Not Found");
        } else {
          handleErrorMessage("Login failed. Please try again.");
        }
      } else {
        handleErrorMessage("Something went wrong please try again later");
      }
      // console.error("here is the error", error);
    } finally {
      setLoading(false);
    }
  };
  //..................................................................................

  const validateForm = () => {
    let valid = true;

    if (!email) {
      setEmailError("Email is required");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      valid = false;
    } else {
      setPasswordError("");
    }

    return valid;
  };

  // const handleOtpEmailChage = (event) => {
  //   setOtpEmail(event.target.value);
  // };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title> Login | EduSkills </title>
      </Helmet>
      <CssBaseline />

      <Paper
        style={{
          minHeight: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            background: "linear-gradient(to bottom,#ffff , #BCE2FF)",
          }}
        >
          <StyledBox>
            <Container>
              <Box
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      display: "flex",
                      justifyContent: "center",
                      mb: 3,
                    },
                  }}
                >
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{
                      maxWidth: "250px",
                      padding: "5px 10px",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    marginRight: 6,
                    marginTop: 8,
                    maxWidth: "800px",
                    [theme.breakpoints.down("md")]: { display: "none" },
                  }}
                >
                  <Typography variant="h2" sx={{ fontSize: "50px" }}>
                    <strong>Connecting</strong> Skilled Talent with Industry
                  </Typography>
                </Box>

                <Box
                  sx={{
                    marginTop: 5,
                    maxWidth: "500px",
                    [theme.breakpoints.down("md")]: { display: "none" },
                  }}
                ></Box>
              </Box>
            </Container>
            <Container component="main" maxWidth="xs">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "20px",
                  border: "1px solid",
                  borderRadius: "5px",
                  mt: 2,
                  [theme.breakpoints.up("md")]: { mr: 3 },
                }}
              >
                <Grid container>
                  <Grid item xs>
                    <Typography variant="h5" sx={{ fontWeight: 200 }}>
                      Hello!
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      Welcome
                    </Typography>
                  </Grid>
                </Grid>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={handleEmailChange}
                    error={emailError !== ""}
                    helperText={emailError}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handlePasswordChange}
                    error={passwordError !== ""}
                    helperText={passwordError}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Sign In"}
                  </Button>
                </Box>
              </Box>
            </Container>
          </StyledBox>
          <Box sx={{ width: "100vw", mb: 2, mt: 5 }}>
            <Divider />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Copyright />
          </Box>
        </Box>
      </Paper>
    </ThemeProvider>
  );
}

export default Login;
