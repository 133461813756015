import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const CommonModal = ({ open, onClose, onConfirm, action, msg }) => {
  const getTitle = () => {
    switch (action) {
      case "warning":
        return "Caution !";
      case "save":
        return "Save Confirmation";
      case "delete":
        return "Delete Confirmation";
      default:
        return "Confirmation";
    }
  };

  const getMessage = () => {
    switch (action) {
      case "warning":
        return msg;
      case "save":
        return "Are you sure you want to save?";
      case "delete":
        return "Are you sure you want to delete?";
      default:
        return "Are you sure?";
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{getTitle()}</DialogTitle>
      <DialogContent>{getMessage()}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Ok
        </Button>
        {!msg && (
          <Button onClick={onConfirm} color="info">
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CommonModal;
