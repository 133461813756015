import React, { useState } from "react";
import questionsData from "../../data/questions.json";
import { setActiveIndex } from "../../store/Slices/assementSlice";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { filter } from "lodash";
const Questions = ({ ansArray }) => {
  const normalizedSelectedValues = ansArray.map((item) =>
    item.choices[0] !== "" ? 1 : 0
  );
  const dispatch = useDispatch();
  const currentIndex = useSelector((state) => state.assementIndex.activeIndex);
  const filterVal = normalizedSelectedValues.slice(0, currentIndex);
  return (
    <div className="rounded-md p-2">
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: "black",
          paddingTop: "10px",
          paddingLeft: "10px",
        }}
      >
        Questions
      </Typography>
      <div className=" flex flex-wrap pt-5 gap-2">
        {questionsData.questions.map((question, index) => (
          <div key={index} className="">
            <button
              // onClick={() => handleQuestionClick(index)}
              className={`p-2 rounded border-2 w-10 border-gray-500 cursor-pointer ${
                currentIndex === index
                  ? "bg-blue-300 text-white"
                  : filterVal[index] === 0
                  ? "bg-red-300 text-white"
                  : filterVal[index] > 0
                  ? "bg-green-300 text-white"
                  : "bg-slate-200"
              }`}
            >
              {index + 1}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Questions;
