// export const BASE_URL =
//   "http://ec2-15-206-231-196.ap-south-1.compute.amazonaws.com/api/v1";
export const BASE_URL = "https://erpapi.eduskillsfoundation.org";

//..............BASE_URL1
// export const BASE_URL = "http://sm.eduskills.academy/api";

// export const API_URLS1 = {
//   LOGIN: "/auth",
//   INSTRUCTION_DATA: "/assessment",
// };

export const API_URLS = {
  //...........BASE_URL1
  LOGIN: "/assessment/sm/auth",
  INSTRUCTION_DATA: "/assessment/sm/",
  ASSESSMENT_QUES: "/assessment/sm/question/",
  ASSESSMENT_SUBMIT: "/assessment/sm/submit/",
  //   // auth..............................................
  // LOGIN: "/token/",
  LOGOUT: "/token/logout",
  SEND_OTP: "/token/send/otp",
  VERIFY_OTP: "/token/verify/otp",
  REFRESH: "/token/refresh",
  ROLES: "/user/roles",
  //   SWITCH_ROLE: "/user/switch/role/",
  //   // dashboard.........................................
  // ASSESSMENT_QUES: "/assessment/question/",
  // ASSESSMENT_SUBMIT: "/assessment/submit/",
  // INSTRUCTION_DATA: "/assessment/",
  //..............................
  // APPLYING_JOB: "/talent/connect/student/jobs/",
  // JOB_DETAILS: "/talent/connect/student/jobs/",
  // INITIAL_DATA: "/talent/connect/student/resume/",
  // JOB_SUBMIT: "/talent/connect/student/resume/",
  // RESUME_SUBMIT: "/talent/connect/student/apply/job",
  // APPLIED_JOB: "/talent/connect/student/jobs/",
  // APPLIED_JOB_DETAILS: "/talent/connect/student/jobs/",
  //.............................
  // PROFILE_UPDATE: "/profile/update/",
  //..............register using otp,email,institute
  //VERIFY_OTP_PROFILE: "/profile/verify/otp/",
};
