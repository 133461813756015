import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import winerCup from "../../assets/imgs/winer.jpg";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Icon } from "@iconify/react";

const ResponseComponent = ({ response, assessment, name }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    localStorage.removeItem("IS_SUBMITTED");
    localStorage.removeItem("i5St@r7t!");
    navigate("/assessment");
  };
  // useEffect(() => {
  //   const isSubmitted = localStorage.getItem("i5St@r7t!");
  //   if (isSubmitted === "@ll0w3d9G!v3!3x@M") {
  //     navigate("/assessment");
  //   }
  // }, [navigate]);

  return (
    <div className="flex justify-center h-screen">
      <div className=" font-bold flex justify-center items-center">
        {response?.data?.grade === 0 ? (
          ""
        ) : (
          <h2 className="text-center text-2xl">
            {/* Your grade is: {response?.data?.data.mark} */}
          </h2>
        )}
        <div className=" w-4/12 h-auto fixed rounded-md shadow-md border-2 border-slate-100">
          {response?.data?.status === true ? (
            <div className="flex flex-col justify-center items-center">
              <h2 className=" text-center text-slate-400 text-xl font-bold mt-5 pt-2">
                Your Result
              </h2>
              <img src={winerCup} alt="winner" className="h-32 w-32 mt-2" />
              {/* <h2 className="font-bold text-base text-center mt-2">
                Congratulation <span>{name}</span>
              </h2> */}
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <Icon
                icon="noto:warning"
                width={60}
                height={60}
                className=" mt-5"
              />
              {/* <img src={winerCup} alt="winner" className="h-32 w-32 mt-2" /> */}
            </div>
          )}
          {response?.data?.status === false ? (
            <p className=" text-center text-red-500 p-5 text-base">
              {response?.data?.detail}
            </p>
          ) : (
            <p className=" text-center text-green-500 p-5 text-base">
              {response?.data?.detail}
            </p>
          )}
          {Object.keys(response?.data?.data || {}).length === 0 ? (
            ""
          ) : (
            <div className=" flex justify-center gap-5 mt-8">
              <Box
                position="relative"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress
                  variant="determinate"
                  // value={response?.data?.data.answer}
                  value={
                    (response?.data?.data.answer /
                      response?.data?.data.question) *
                    100
                  }
                  size={90}
                  thickness={3}
                  color="success"
                />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                  flexDirection="column"
                >
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      lineHeight: "0.2",
                    }}
                  >
                    <Typography
                      variant="h4"
                      component="div"
                      style={{
                        margin: 0,
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {/* 12/15 */}
                      {response?.data?.data.answer}/15
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{
                        fontSize: "13px",
                        lineHeight: "0.8",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                      Correct Answers
                    </Typography>
                  </div>
                </Box>
              </Box>
              <Box
                position="relative"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress
                  variant="determinate"
                  value={response?.data?.data.mark}
                  // value={yourScore}
                  size={90}
                  thickness={3}
                  color="secondary"
                />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                  flexDirection="column"
                >
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "black",
                      lineHeight: "0.2",
                    }}
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      color="blueGray"
                      style={{
                        margin: 0,
                        fontSize: "16px",
                        fontWeight: "bold",
                        lineHeight: "0.8",
                      }}
                    >
                      {response?.data?.data.mark}
                      {/* {yourScore} */}
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{
                        margin: 0,
                        fontSize: "13px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                      Your Grade
                    </Typography>
                  </div>
                </Box>
              </Box>
              <Box
                position="relative"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress
                  variant="determinate"
                  // value={100}
                  value={(response?.data?.data.duration / 15) * 100}
                  size={90}
                  thickness={3}
                  color="warning"
                />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                  flexDirection="column"
                >
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "black",
                      lineHeight: "0.2",
                    }}
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      style={{
                        margin: 0,
                        fontSize: "14px",
                        fontWeight: "bold",
                        lineHeight: "0.8",
                      }}
                    >
                      {/* 15 */}
                      {response?.data?.data.duration}
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: "13px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                      Total Time
                    </Typography>
                  </div>
                </Box>
              </Box>
            </div>
          )}

          <div className=" flex justify-center mb-5 mt-10">
            <button
              className=" bg-yellow-400 text-center h-9 w-48 p-2 font-semibold rounded-md mt-5"
              onClick={handleClose}
            >
              Go Home
              {/* Get Your Certificate */}
            </button>
          </div>
        </div>

        {/* <p className="text-center text-lg">{response?.data?.detail}</p>
        <button
          className=" border-2 border-red-300 w-14 h-8 ml-44 mt-5 rounded-md hover:scale-105 hover:bg-red-300 hover:text-white"
          onClick={handleClose}
        >
          Close
        </button> */}
      </div>
    </div>
  );
};

export default ResponseComponent;
