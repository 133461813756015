import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./Slices/authSlice";
import authoriseReducer from "./Slices/authoriseSlice";
import assementSlice from "./Slices/assementSlice";

export const rootReducer = combineReducers({
  auth: authReducer,
  authorise: authoriseReducer,
  assementIndex: assementSlice,
});
