import api from "./api";
import axios from "axios";
import { BASE_URL, API_URLS } from "./configUrls";
// import { BASE_URL1, API_URLS1 } from "./configUrls";

// //base url for sm project................................
// export const testService = axios.create({
//   baseURL: BASE_URL1,
// });
//.........................
// export const AuthService={
// login: async (email, password) => {
//   try {
//     const response = await dataService.post(API_URLS1.LOGIN, {
//       email,
//       password,
//     });
//     console.log("response through email and password", response);
//     return response; // Optionally return response data
//   } catch (error) {
//     throw error;
//     // handleServiceError(error, "Login failed");
//   }
// },
//   logout: async () => {
//     try {
//       const response = await api.post(API_URLS1.LOGOUT, {
//         refresh_token: localStorage.getItem("refreshToken"),
//       });
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   },
//   roles: async () => {
//     try {
//       const response = await api.get(API_URLS.ROLES);
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   },
//   switch_role: async (id) => {
//     try {
//       const response = await api.put(`${API_URLS.SWITCH_ROLE}${id}`);
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   },
// }
// export const AssementSubmit = {
//   testSubmit: async (id, data) => {
//     try {
//       const response = await api.post(
//         `${API_URLS1.ASSESSMENT_SUBMIT}${id}`,
//         data
//       );
//       return response;
//     } catch (error) {
//       throw error;
//       // handleServiceError(error, "assessment submition  failed");
//     }
//   },
// };
// export const instructionService = {
//   instruction: async () => {
//     try {
//       const response = await api.get(API_URLS1.INSTRUCTION_DATA);
//       return response; // Optionally return response data
//     } catch (error) {
//       throw error;
//       // handleServiceError(error, "Login failed");
//     }
//   },
// };
// //...............talent connect page

// //...........list of jobs data
// export const applyingJobService = {
//   jobApply: async () => {
//     try {
//       const response = await api.get(API_URLS1.APPLYING_JOB);
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   },
// };

//.........................

const dataService = axios.create({
  baseURL: BASE_URL,
});

// // AuthService --------------------------------------------------

export const AuthService = {
  //...........
  // verifyOtp: async (email, otp, institute) => {
  //   try {
  //     const response = await dataService.post(API_URLS.VERIFY_OTP_PROFILE, {
  //       email: email,
  //       otp: otp,
  //       institute: institute,
  //     });
  //     return response; // Optionally return response data
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  //............
  login: async (email, password) => {
    try {
      const response = await dataService.post(API_URLS.LOGIN, {
        email,
        password,
      });
      return response; // Optionally return response data
    } catch (error) {
      console.log("error reason", error);
      throw error;
      // handleServiceError(error, "Login failed");
    }
  },
  logout: async () => {
    try {
      const response = await api.post(API_URLS.LOGOUT, {
        refresh_token: localStorage.getItem("refreshToken"),
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  sendOtp: async (otpData) => {
    try {
      const response = await dataService.post(API_URLS.SEND_OTP, otpData);
      return response; // Optionally return response data
    } catch (error) {
      throw error;
    }
  },
  verifyOtp: async (email, otp) => {
    try {
      const response = await dataService.post(API_URLS.VERIFY_OTP, {
        email: email,
        otp: otp,
      });
      return response; // Optionally return response data
    } catch (error) {
      throw error;
    }
  },
  roles: async () => {
    try {
      const response = await api.get(API_URLS.ROLES);
      return response;
    } catch (error) {
      throw error;
    }
  },
  switch_role: async (id) => {
    try {
      const response = await api.put(`${API_URLS.SWITCH_ROLE}${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export const AssementService = {
  question: async (id) => {
    try {
      const response = await api.get(`${API_URLS.ASSESSMENT_QUES}${id}`);
      return response; // Optionally return response data
    } catch (error) {
      throw error;
      // handleServiceError(error, "Login failed");
    }
  },
};

export const AssementSubmit = {
  testSubmit: async (id, data) => {
    try {
      const response = await api.post(
        `${API_URLS.ASSESSMENT_SUBMIT}${id}`,
        data
      );
      return response;
    } catch (error) {
      throw error;
      // handleServiceError(error, "assessment submition  failed");
    }
  },
};
export const instructionService = {
  instruction: async () => {
    try {
      const response = await api.get(API_URLS.INSTRUCTION_DATA);
      return response; // Optionally return response data
    } catch (error) {
      throw error;
      // handleServiceError(error, "Login failed");
    }
  },
};
//...............talent connect page

//...........list of jobs data
// export const applyingJobService = {
//   jobApply: async () => {
//     try {
//       const response = await api.get(API_URLS.APPLYING_JOB);
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   },
// };
//...........Jobs details page
// export const JobDescriptionService = {
//   jobDescription: async (id) => {
//     try {
//       const response = await api.get(`${API_URLS.JOB_DETAILS}${id}`);
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   },
// };

//......initial load data if data have of that user
// export const initialLoadDataService = {
//   initialLoadData: async () => {
//     try {
//       const response = await api.get(API_URLS.INITIAL_DATA);
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   },
// };
//............Resume apply
// export const submitJobService = {
//   submittedJobData: async (data) => {
//     try {
//       const response = await api.post(`${API_URLS.JOB_SUBMIT}`, data);
//       return response;
//     } catch (error) {
//       console.log("error where?", error);
//     }
//   },
// };
//.......................
// export const submitResumeService = {
//   submittedResumeData: async (data) => {
//     try {
//       const response = await api.post(`${API_URLS.RESUME_SUBMIT}`, data);
//       return response;
//     } catch (error) {
//       console.log("error where?", error);
//     }
//   },
// };
//...........list of applied jobs
// export const appliedJobService = {
//   appliedJobData: async () => {
//     try {
//       const response = await api.get(API_URLS.APPLIED_JOB);
//       return response;
//     } catch (error) {
//       console.log(error);
//     }
//   },
// };
//............Applied Job Details
// export const appliedJobDetailsService = {
//   appliedJobDetails: async (id) => {
//     try {
//       const response = await api.get(`${API_URLS.APPLIED_JOB_DETAILS}${id}`);
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   },
// };

// export const profileUpdateDataService = {
//   updateProfile: async (data) => {
// console.log("profileData", data);
//     try {
//       const response = await api.post(`${API_URLS.PROFILE_UPDATE}`, data);
//       return response;
//     } catch (error) {
//       console.log(error);
//     }
//   },
// };

//Export the data service if needed
// export default dataService;
