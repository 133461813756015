import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeIndex: parseInt(localStorage.getItem("activeIndex"), 10) || 0,
  isStart: false,
};

const assementSlice = createSlice({
  name: "assement",
  initialState,
  reducers: {
    setActiveIndex: (state, action) => {
      state.activeIndex = action.payload;
      localStorage.setItem("activeIndex", action.payload.toString());
    },
    setIsStart: (state, action) => {
      state.isStart = action.payload;
    },
    clearActiveIndex: (state) => {
      state.activeIndex = 0;
      state.isStart = false;
    },
  },
});

export const { setActiveIndex, clearActiveIndex, setIsStart } =
  assementSlice.actions;

export default assementSlice.reducer;
