import axios from "axios";
import store from "../store/store";
import { clearTokens, setTokens } from "../store/Slices/authSlice";
import { BASE_URL, API_URLS } from "./configUrls";
// import { BASE_URL1, API_URLS1 } from "./configUrls";

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");
      try {
        const refreshResponse = await api.post(API_URLS.REFRESH, {
          refresh_token: refreshToken,
        });

        const newAccessToken = refreshResponse.data.access_token;

        if (newAccessToken) {
          store.dispatch(
            setTokens({
              accessToken: newAccessToken,
              refreshToken: refreshToken, // Keep the existing refresh token
            })
          );
          localStorage.setItem("accessToken", newAccessToken);
          return api(originalRequest);
        } else {
          console.error("No new access token provided during refresh.");
          store.dispatch(clearTokens());
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          localStorage.clear();
          return Promise.reject(error);
        }
      } catch (refreshError) {
        store.dispatch(clearTokens());
        // localStorage.removeItem("accessToken");
        // localStorage.removeItem("refreshToken");
        localStorage.clear();
        // Handle refresh token failure
        throw refreshError;
      }
    }
    // console.error("Request failed with status:", error.response.status);
    return Promise.reject(error);
  }
);

export default api;
