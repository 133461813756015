import React from "react";
import { Navigate, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
const AuthProtected = (props) => {
  const history = useLocation();
  const { accessToken, refreshToken } = useSelector((state) => state.auth);
  const userRole = localStorage.getItem("Authorise");
  if (accessToken && refreshToken) {
    return <>{props.children}</>;
  } else {
    if (history) {
      localStorage.setItem("TRIGGERD_PATH", history.pathname);
    }
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
