import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { instructionService } from "../../services/dataService";
import { InfinitySpin } from "react-loader-spinner";

const Assessments = () => {
  const [instructionData, setInstructionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/instructions");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await instructionService.instruction();
        const assessmentData = response?.data?.data[0];
        localStorage.setItem("ASSESSMENT_ID", assessmentData?.assessment_id);
        localStorage.setItem(
          "i5St@r7t!",
          assessmentData?.status === "Start"
            ? "@ll0w3d2G!v3!3x@M"
            : "@ll0w3d9G!v3!3x@M"
        );

        setInstructionData(assessmentData);
      } catch (error) {
        console.error(error);
        localStorage.setItem("i5St@r7t!", "@ll0w3d9G!v3!3x@M");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container mx-auto mt-5 p-3">
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <InfinitySpin
            visible={true}
            width={200}
            color="#4fa94d"
            ariaLabel="infinity-spin-loading"
          />
        </div>
      ) : (
        <div>
          <h2 className="text-lg font-bold tracking-wider h-10 rounded-md shadow-sm p-1">
            Assessments
          </h2>
          {instructionData ? (
            <div className="overflow-x-auto">
              <table className="min-w-full shadow-sm border-gray-300 mt-8">
                <thead>
                  <tr className="text-base">
                    <th className="py-2 px-4 border-b">SL</th>
                    <th className="py-2 px-4 border-b text-left">Exam Name</th>
                    <th className="py-2 px-1 border-b">Duration</th>
                    <th className="py-2 px-4 border-b">No.Mcq</th>
                    {/* <th className="py-2 px-4 border-b">Start Date</th> */}
                    <th className="py-2 px-4 border-b">
                      {instructionData.status === "Start"
                        ? "Start Date"
                        : "Grade"}
                    </th>
                    <th className="py-2 px-4 border-b">
                      {instructionData.status === "Start"
                        ? "End Date"
                        : "Completed Date"}
                    </th>

                    <th className="py-2 px-4 border-b">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="py-2 px-4 border-b text-center">
                      {instructionData?.sl_no}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {instructionData?.assessment}
                    </td>
                    <td className="py-2 px-1 border-b text-center">
                      {instructionData?.duration} Mins
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {instructionData?.mcq}
                    </td>
                    {/* <td className="py-2 px-4 border-b text-center">
                      {instructionData.start_date}
                    </td> */}
                    <td
                      className={`py-2 px-4 border-b text-center font-${
                        instructionData?.status === "Start" ? "" : "bold"
                      }`}
                    >
                      {instructionData?.status === "Start"
                        ? instructionData?.start_date
                        : instructionData?.grade}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {instructionData?.end_date}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      <button
                        className={`w-auto h-8 bg-${
                          instructionData?.status === "Start"
                            ? "sky"
                            : instructionData?.status === "completed"
                            ? "green"
                            : "red"
                        }-400 rounded-md p-1 text-white text-base px-2 py-1`}
                        onClick={handleNext}
                        disabled={instructionData?.status !== "Start"}
                      >
                        {instructionData?.status}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="flex flex-col h-20 w-full rounded-md">
              <h3 className="text-center mt-5 text-base tracking-normal">
                No Assessments available
              </h3>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Assessments;
